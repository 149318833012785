export const createCookie = (name: string, value: string, domain: string, secureFlag: string) => {
  return `${name}=${value}; Path=/; Domain=${domain}; Max-Age=604800; SameSite=Lax; ${secureFlag};`
}

export const getCookie = (name: string) => {
  const cookieArr = document.cookie.split(';')
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].trim()

    if (cookiePair.startsWith(name + '=')) {
      return cookiePair.substring(name.length + 1)
    }
  }
  return null
}

export const deleteCookie = (name: string, domain: string, secureFlag: string) => {
  document.cookie = `${name}=; expires=${new Date().toUTCString()}; Domain=${domain}; Max-Age=0; Path=/; SameSite=Lax; ${secureFlag};`
}
