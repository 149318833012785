import _ from 'lodash'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'

export interface IDynamicMetaProps {
  title?: string
  subtitle?: string | null
  keywords?: string
  ogTitle?: string
  ogSubtitle?: string
  url?: string
  ogImage?: {
    property: string
    content: string | null
  }
  ogImageSecure?: {
    property: string
    content: string | null
  }
}

const DynamicMeta: FC<IDynamicMetaProps> = ({
  title = '멋쟁이사자처럼',
  ogTitle = '멋쟁이사자처럼',
  subtitle = 'IT분야의 TECH 인재를 키우는 교육 커뮤니티, 멋쟁이사자처럼!',
  ogSubtitle = 'IT분야의 TECH 인재를 키우는 교육 커뮤니티, 멋쟁이사자처럼!',
  keywords = 'KDT 멋쟁이사자처럼 국비지원, 멋쟁이사자처럼, KDT, 커리어, IT 교육, LIKELION, K digital training, 부트캠프, 코딩, 국비지원 교육, 멋쟁이사자처럼 부트캠프, 멋사, 멋쟁이사자처럼 후기, 멋사 국비지원 부트캠프, 멋사 6주 완성, 멋쟁이사자처럼 6주 완성, 멋사 해커톤, 해커톤',
  url = 'https://likelion.net/',
  ogImage = {
    property: 'og:image',
    content: '/img/og_likelion.webp',
  },
  ogImageSecure,
}) => {
  const router = useRouter()
  const isLabeledTitle = useMemo(
    () =>
      [
        '/',
        '/school',
        '/school/[schoolId]',
        '/onboarding',
        '/onboarding/[schoolId]',
        '/startup-station/[schoolId]',
        '/content',
        '/event',
        '/enterprise',
        '/pre-notice',
      ].includes(router.pathname),
    [router.pathname],
  )
  const isLabeledOgTitle = useMemo(
    () =>
      ['/school/[schoolId]', '/onboarding/[schoolId]', '/startup-station/[schoolId]'].includes(
        router.pathname,
      ),
    [router.pathname],
  )

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta name="naver-site-verification" content="12e9dadf847976934cc1a35fea7a61f97f98352b" />
      <meta name="keywords" content={keywords} key="keywords" />
      <meta property="og:type" content="website" key="ogType" />
      {isLabeledTitle ? <title>{title} : 멋쟁이사자처럼</title> : <title>{title}</title>}
      {isLabeledOgTitle ? (
        <meta property="og:title" content={`${ogTitle} : 멋쟁이사자처럼`} key="ogTitle" />
      ) : (
        <meta property="og:title" content={`${ogTitle}`} key="ogTitle" />
      )}
      <meta property="og:url" content={url} key="ogUrl" />
      {subtitle !== null && <meta name="description" content={subtitle} key="description" />}
      <meta property="og:description" content={ogSubtitle ?? subtitle} key="ogDescription" />
      {ogImage.content !== null && (
        <meta property={ogImage.property} content={ogImage.content} key="ogImage" />
      )}
      {ogImageSecure && ogImageSecure.content !== null && (
        <meta
          property={ogImageSecure.property}
          content={ogImageSecure.content}
          key="ogImageSecure"
        />
      )}
    </Head>
  )
}

export default DynamicMeta
